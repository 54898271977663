import { DestroyRef, Injectable, WritableSignal, signal } from '@angular/core'
import { LogService } from './log.service'
import { JsonLogicService } from './json-logic.service'
import { FetchService } from './fetch.service'
import { ApiRequestService } from './api-request.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { HttpErrorResponse } from '@angular/common/http'
import { ClientService } from './client.service'
import { FilterId, IFilter, IFilterCommands, IFilterSettings, IGetFiltersResponse, IHasFilters, IIsFilters, INotFilters } from '../interfaces/filter.interface'
import { IJsonLogic } from '../interfaces/json-logic.interface'

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  filterSettings: IFilterSettings = {}
  filters: WritableSignal<IFilter[]> = signal([])

  constructor(
    private _logger: LogService,
    private _jsonLogicService: JsonLogicService,
    private _fetchService: FetchService,
    private _apiRequestService: ApiRequestService,
    private _destroyRef: DestroyRef,
    private _clientService: ClientService,
  ) {}

  private _is: IIsFilters = {
    listed: {
      ...this._jsonLogicService.equals(
        this._jsonLogicService.var('filter_is_listed'),
        'true',
      )
    },
    featured: {
      ...this._jsonLogicService.equals(
        this._jsonLogicService.var('filter_is_featured'),
        'true',
      )
    },
    equipment: (id: string) => ({
      ...this._jsonLogicService.equals(
        this._jsonLogicService.var('equipment_number'),
        id,
      )
    }),
  }

  private _not: INotFilters = {
    featured: {
      ...this._jsonLogicService.doesNotEqual(
        this._jsonLogicService.var('filter_is_featured'),
        'true',
      )
    },
    equipment: (id: string) => ({
      ...this._jsonLogicService.doesNotEqual(
        this._jsonLogicService.var('equipment_number'),
        id,
      )
    }),
  }

  private _has: IHasFilters = {
    price: {
      ...this._jsonLogicService.gt(
        this._jsonLogicService.var('list_price'),
        0,
      )
    }
  }

  build(
    processor: (commands: IFilterCommands) => IJsonLogic,
  ) {
    const result = processor({
      and: this._jsonLogicService.and,
      or: this._jsonLogicService.or,
      inArray: this._jsonLogicService.inArray,
      variable: this._jsonLogicService.var,
      equals: this._jsonLogicService.equals,
      doesNotEqual: this._jsonLogicService.doesNotEqual,
      lt: this._jsonLogicService.lt,
      lte: this._jsonLogicService.lte,
      gt: this._jsonLogicService.gt,
      gte: this._jsonLogicService.gte,
      is: this._is,
      not: this._not,
      has: this._has,
    })
    return result
  }

  loadFilterSettings(): Promise<void> {
    return new Promise((resolve, reject) => {
      const endpoint = this._apiRequestService.buildEndpoint('webshop/equipment/filters', {
        meter_uod: this._clientService.getMeterUod(),
      })
      this._fetchService.postRequest<IGetFiltersResponse>(endpoint, {
        fields: [
          ['category', 'sub_category'],
          ['make', 'model'],
          ['branch_state'],
          ['meter_miles_precise'],
          ['meter_hours'],
          ['list_price'],
          ['year'],
        ],
      }).pipe(
        takeUntilDestroyed(this._destroyRef),
      ).subscribe(response => {
        if (response instanceof HttpErrorResponse) {
          this._logger.error('Unable to load filters.  ', response)
          return reject()
        }
        this.filterSettings = response.results[0]
        resolve()
      })
    })
  }

  loadFilterState(encodedFiltersData: string | null): void {
    if (encodedFiltersData) {
      this.filters.set(this.decodeFilters(encodedFiltersData) as IFilter[])
    }
  }

  clearFilterState(): void {
    this.filters.set([])
  }

  getFilterState(filterId: FilterId): IFilter['state'] {
    return this.filters().find(f => f.id === filterId)
  }

  updateFilters(filterId: FilterId, filterState: any): void {
    const index = this.filters().findIndex(f => f.id === filterId)
    if (index >= 0) {
      this.filters().splice(index, 1)
    }
    if (filterState) {
      this.filters().push({
        id: filterId,
        state: filterState,
      })
    }
  }

  encodeFilters(filters: IFilter[]): string {
    return btoa(JSON.stringify(filters))
  }

  decodeFilters(filters: string): any {
    return JSON.parse(atob(filters))
  }
}
